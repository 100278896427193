<script
    lang="ts"
    setup
>
    type Props = {
        modelValue: any
        name: string
        placeholder?: string
        disabled?: boolean
        small?: boolean
    }

    type Emit = {
        (event: 'update:modelValue', value: any): void
    }

    const props = withDefaults(defineProps<Props>(), {
        placeholder: undefined,
        disabled: false,
        small: false
    })

    const emit = defineEmits<Emit>()

    const inputModel = useSyncProp<string>(props, 'modelValue', emit)

    const style = useCssModule()

    const containerClass = computed<string[]>(() => {
        const classes = [ style['search'] ]

        if (props.small) {
            classes.push(style['search--small'])
        }

        if (props.disabled) {
            classes.push(style['search--disabled'])
        }

        return classes
    })
</script>

<template>
    <div :class="containerClass">
        <AppIconSearch
            key="icon-search"
            size="16"
            :class="[ $style['search__icon'], $style['search__icon--search'] ]"
        />

        <input
            key="input"
            ref="inputRef"
            v-model="inputModel"
            :name="props.name"
            type="text"
            :disabled="props.disabled"
            :placeholder="props.placeholder"
            :class="$style['search__input']"
            autocomplete="off"
        />

        <Transition name="scale-transition">
            <AppIconClose
                v-if="inputModel"
                key="icon-close"
                size="16"
                :class="[ $style['search__icon'], $style['search__icon--close'] ]"
                @click="inputModel = ''"
            />
        </Transition>
    </div>
</template>

<style
    lang="sass"
    module
>
    .search
        &--small
            --width: 250px
            --height: 30px
            --icon-margin: 0 4px 0 8px

        &--disabled
            cursor: not-allowed !important

            > *
                pointer-events: none

        overflow: hidden
        display: flex
        align-items: center
        background: #f6f7f8
        width: var(--width, 450px)
        max-height: var(--height, 40px)
        min-height: var(--height, 40px)
        border-radius: 8px

        &:focus-within &
            &__icon--search
                > path
                    transition: none
                    fill: #000

        &__icon
            margin: var(--icon-margin, 0 8px 0 12px)

            &--search
                pointer-events: none

                > path
                    transition: fill var(--transition-default-duration-with-ease)
                    will-change: fill

            &--close
                cursor: pointer

        &__input
            appearance: none
            outline: none
            border: none
            flex: 1
            width: 100%
            height: 100%
            font-style: normal
            font-weight: 400
            font-size: 14px
            line-height: 130%
            background: none
            color: #000
            caret-color: auto

            --placeholder-color: #8a8f9e

            &::-webkit-input-placeholder
                color: var(--placeholder-color)

            &::-moz-placeholder
                color: var(--placeholder-color)

            &:-ms-input-placeholder
                color: var(--placeholder-color)

            &:-moz-placeholder
                color: var(--placeholder-color)
</style>
